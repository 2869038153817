export default class ProgressUtil {
  public static getProgressColor(currentProgress, limit): string {
    const progress = parseInt(currentProgress, 10);
    let red = 0;
    let green = 0;
    const blue = 0;

    if (progress <= 50 && progress > 0) {
      red = limit;
      green = (progress / 50) * limit;
    }

    if (progress > 50) {
      green = limit;
      red = ((100 - progress) / 50) * limit;
    }

    return `rgb(${red},${green},${blue})`;
  }
}
