/* eslint-disable @typescript-eslint/no-explicit-any */
import { mapActions, mapGetters } from 'vuex';

import { ExhibitorRep } from '@/models/exhibitor/exhibitor.rep.model';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import {
  CREATE_MEETING_FOR_USER,
  CreateMeetingForUserDetail,
  EXIT_KIOSK,
  SAVE_KIOSK_SCREENSHOT,
  SAVE_KIOSK_THUMBNAIL,
  SaveKioskScreenshotDetail,
  SaveKioskThumbnailDetail,
  START_EXHIBITOR_CHAT,
  START_EXHIBITOR_REP_VISIO,
  START_USER_CHAT,
  START_VIDEO,
  StartExhibitorChatDetail,
  StartExhibitorRepVisioDetails,
  StartUserChatDetail,
  StartVideoDetail,
  VISIT_KIOSK,
  VisitKioskDetail,
} from '@/unity';
import { Exhibitor, User } from '@/models';
import { userBookingLocation } from '@/navigation';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { GET_EXHIBITOR } from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import {
  APP_EXHIBITOR_MODULE,
  CONNECT_TO_EXHIBITOR_VISITOR_SOCKET,
  DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET,
} from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import {
  ADMIN_EXHIBITOR_MODULE,
  UPLOAD_EXHIBITOR_SCREENSHOT,
} from '@/stores/umanize-admin/actions/exhibitor/exhibitor.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import DataUtil from '@/helpers/data/data.helper';
import { ExhibitorScreenShotType } from '@/models/exhibitor/ExhibitorScreenShotType';
import { whereByIframeSrcBuilder } from '@/helpers/whereby/whereby.helper';

export const unityEventListenersMixin = {
  computed: {
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitor', 'exhibitorUsers']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
  },
  methods: {
    ...mapActions(APP_EXHIBITOR_MODULE, [
      CONNECT_TO_EXHIBITOR_VISITOR_SOCKET,
      DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET,
      GET_EXHIBITOR,
    ]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(ADMIN_EXHIBITOR_MODULE, [UPLOAD_EXHIBITOR_SCREENSHOT]),
    getEventId() {
      const { eventId } = (this as any).$route.params;
      return eventId;
    },

    /**
     * Calls the store to get the exhibitor with the given id
     * relative to the event id param in the current route.
     *
     * @param exhibitorId the id of the exhibitor
     */
    async getExhibitor(exhibitorId) {
      const eventId = this.getEventId();

      await this[GET_EXHIBITOR]({
        eventId,
        exhibitorId,
      });
    },

    /**
     * Opens up a chat between the current user and all reps
     * of the exhibitor found in the given "event".
     *
     * @param event the event we are listening to
     */
    async openExhibitorChat(event: CustomEvent<StartExhibitorChatDetail>) {
      const {
        detail: { exhibitorId },
      } = event;
      await this.getExhibitor(exhibitorId);
      const userIds = this['exhibitorUsers'].map((u: ExhibitorRep) => u.id);

      if (userIds.length > 0) {
        this[OPEN_CHAT]({ userIds });
      }
    },

    /**
     * Opens up a chat between the current user and another user.
     *
     * @param event the event we are listening to
     */
    async openUserChat(event: CustomEvent<StartUserChatDetail>) {
      const {
        detail: { userId },
      } = event;
      this[OPEN_CHAT]({ userIds: [userId] });
    },

    /**
     * Shows the video that the exhibitor wants us to see.
     * NOTE: We need the component to implement his own "showVideo" method
     *       that we call here.
     *
     * @param event the event containing the URL of the video
     */
    openExhibitorVideo(event: CustomEvent<StartVideoDetail>) {
      const {
        detail: { videoUrl },
      } = event;
      (this as any).showVideo(videoUrl);
    },
    /**
     * Opens up a video chat between the current user and the rep
     * of the exhibitor found in the given "event".
     *
     * @param event the event we are listening to
     */
    async openExhibitorRepVideoChat(event: CustomEvent<StartExhibitorRepVisioDetails>) {
      const {
        detail: { exhibitorId, userId },
      } = event;

      await this.getExhibitor(exhibitorId);

      (this as any).showVideoChat(this.exhibitorRepVideoChatURL(userId));
    },

    /**
     * Returns the whereby URL of the representative id of the
     * currently loaded exhibitor.
     *
     * @param repUserId the user id of the exhibitor rep
     */
    exhibitorRepVideoChatURL(repUserId: string) {
      if (!(this as any).exhibitor || !(this as any).loggedInUser) {
        return '';
      }

      const exh = (this as any).exhibitor as Exhibitor;
      const exhibitorUser = exh.users.find((u: ExhibitorRep) => u.id === repUserId);
      if (!exhibitorUser) {
        return '';
      }
      return whereByIframeSrcBuilder(exhibitorUser.wherebyUrl, {
        embed: true,
        iframeSource: 'umanize',
        background: 'on',
        chat: 'on',
        people: 'off',
        screenShare: 'on',
        recording: 'on',
        displayName: this.loggedUserDisplayName(),
      });
    },

    loggedUserDisplayName() {
      const user = ((this as any).loggedInUser as unknown) as User;
      return encodeURIComponent(`${user.firstName} ${user.lastName}`);
    },

    /**
     * Redirect the logged in user to the booking page of the user
     * @param dispatchedEvent
     */
    createMeetingForUser(dispatchedEvent: CustomEvent<CreateMeetingForUserDetail>) {
      const {
        detail: { userId },
      } = dispatchedEvent;
      const eventId = this.getEventId();

      const location = userBookingLocation({ eventId, userId });
      const { href } = (this as any).$router.resolve(location);
      window.open(href, '_blank');
    },
    /**
     * Save the received picture in the exhibitor object
     * @param dispatchedEvent containing the picture to save as a byte array
     */
    async saveExhibitorKioskScreenshot(dispatchedEvent: CustomEvent<SaveKioskScreenshotDetail>) {
      const {
        detail: { picture },
      } = dispatchedEvent;

      // get event id and currentExhibitor in order to update exhibitor later
      const eventId: string = this['event'].id;
      const exhibitorId: Exhibitor = this['exhibitor'].id;

      // convert and upload the file
      await this[UPLOAD_EXHIBITOR_SCREENSHOT]({
        eventId,
        exhibitorId,
        screenshot: DataUtil.b64toBlob(picture),
        pictureType: ExhibitorScreenShotType.MAIN,
      });
    },
    /**
     * Save the received picture in the exhibitor object
     * @param dispatchedEvent containing the picture to save as a byte array
     */
    async saveExhibitorKioskThumbnail(dispatchedEvent: CustomEvent<SaveKioskThumbnailDetail>) {
      const {
        detail: { picture },
      } = dispatchedEvent;

      // get event id and currentExhibitor in order to update exhibitor later
      const eventId: string = this['event'].id;
      const exhibitorId: Exhibitor = this['exhibitor'].id;

      // convert and upload the file
      await this[UPLOAD_EXHIBITOR_SCREENSHOT]({
        eventId,
        exhibitorId,
        screenshot: DataUtil.b64toBlob(picture),
        pictureType: ExhibitorScreenShotType.THUMBNAIL,
      });
    },
    visitKiosk(dispatchedEvent: CustomEvent<VisitKioskDetail>) {
      const {
        detail: { exhibitorId },
      } = dispatchedEvent;
      const eventId: string = this['event'].id;
      this[CONNECT_TO_EXHIBITOR_VISITOR_SOCKET]({ eventId, exhibitorId });
    },
    exitKiosk() {
      this[DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET]();
    },
  },
  mounted() {
    window.addEventListener(START_EXHIBITOR_CHAT, (this as any).openExhibitorChat);
    window.addEventListener(START_USER_CHAT, (this as any).openUserChat);
    window.addEventListener(START_VIDEO, (this as any).openExhibitorVideo);
    window.addEventListener(START_EXHIBITOR_REP_VISIO, (this as any).openExhibitorRepVideoChat);
    window.addEventListener(CREATE_MEETING_FOR_USER, (this as any).createMeetingForUser);
    window.addEventListener(SAVE_KIOSK_SCREENSHOT, (this as any).saveExhibitorKioskScreenshot);
    window.addEventListener(SAVE_KIOSK_THUMBNAIL, (this as any).saveExhibitorKioskThumbnail);
    window.addEventListener(VISIT_KIOSK, (this as any).visitKiosk);
    window.addEventListener(EXIT_KIOSK, (this as any).exitKiosk);
  },
  beforeUnmount() {
    window.removeEventListener(START_EXHIBITOR_CHAT, (this as any).openExhibitorChat);
    window.removeEventListener(START_USER_CHAT, (this as any).openUserChat);
    window.removeEventListener(START_VIDEO, (this as any).openExhibitorVideo);
    window.removeEventListener(START_EXHIBITOR_REP_VISIO, (this as any).openExhibitorRepVideoChat);
    window.removeEventListener(CREATE_MEETING_FOR_USER, (this as any).createMeetingForUser);
    window.removeEventListener(SAVE_KIOSK_SCREENSHOT, (this as any).saveExhibitorKioskScreenshot);
    window.removeEventListener(VISIT_KIOSK, (this as any).visitKiosk);
    window.removeEventListener(EXIT_KIOSK, (this as any).exitKiosk);
  },
};
