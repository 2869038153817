/* eslint-disable no-param-reassign */
import HttpHelper from '@/helpers/http/http.helper';

export default class UnityUtil {
  public static setUnityScript(loaderUrl, createInstance) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = loaderUrl;
      script.onload = async () => {
        createInstance();
      };

      script.addEventListener('error', reject);
      script.addEventListener('abort', reject);
      script.addEventListener('load', () => {
        script.setAttribute('data-loaded', 'true');
        resolve(script);
      });

      document.head.appendChild(script);
    });
  }

  public static onUnityProgress(unityInstance, progress, unityInitCallback) {
    if (!unityInstance.Module) {
      return;
    }

    if (!unityInstance.logo) {
      unityInstance.logo = document.createElement('div');
      unityInstance.logo.className = `logo ${unityInstance.Module.splashScreenStyle}`;
      unityInstance.container.appendChild(unityInstance.logo);
    }

    if (!unityInstance.progress) {
      unityInstance.progress = document.createElement('div');
      unityInstance.progress.className = `progress ${unityInstance.Module.splashScreenStyle}`;
      unityInstance.progress.empty = document.createElement('div');
      unityInstance.progress.empty.className = 'empty';
      unityInstance.progress.appendChild(unityInstance.progress.empty);
      unityInstance.progress.full = document.createElement('div');
      unityInstance.progress.full.className = 'full';
      unityInstance.progress.appendChild(unityInstance.progress.full);
      unityInstance.container.appendChild(unityInstance.progress);
    }

    unityInstance.progress.full.style.width = `${100 * progress}%`;
    unityInstance.progress.empty.style.width = `${100 * (1 - progress)}%`;

    if (progress === 1) {
      unityInstance.logo.style.display = 'none';
      unityInstance.progress.style.display = 'none';

      // Send user jwt token to unity
      const jwt = localStorage.getItem(HttpHelper.JWT_TOKEN);
      unityInstance.SendMessage('Character', 'AuthToken', jwt);

      if (unityInitCallback) {
        unityInitCallback(unityInstance);
      }
    }
  }

  public static onUnitySuccess(unityInstance, unityInitCallback) {
    // Send user jwt token to unity
    const jwt = localStorage.getItem(HttpHelper.JWT_TOKEN);
    unityInstance.SendMessage('Character', 'AuthToken', jwt);

    if (unityInitCallback) {
      unityInitCallback(unityInstance);
    }
  }

  public static formattedConfig(url, config) {
    const mainUrl = url.substring(0, url.lastIndexOf('/')).replace('/Build', '');

    return {
      ...config,
      codeUrl: `${mainUrl}/${config.codeUrl}`,
      dataUrl: `${mainUrl}/${config.dataUrl}`,
      frameworkUrl: `${mainUrl}/${config.frameworkUrl}`,
    };
  }
}
